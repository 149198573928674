import { getCookieValue } from './App';
import config from './config/env.json';


export async function fetchBankAccountBalance(accountId) {
  const token = getCookieValue('firebaseToken');
  try {
    const formData = new FormData();
    formData.append('accountId', accountId);

    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/baas/balances/single-account`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: formData
    });

    if (!response.ok) {
      const rawResponse = await response.text();
      const errorResponse = JSON.parse(rawResponse);
      if (errorResponse.fdt_error_code === "UNLIMIT_PLANNED_MAINTENANCE") {
        throw new Error("UNLIMIT_PLANNED_MAINTENANCE");
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    }  

    const result = await response.json();
    const formatter2 = new Intl.NumberFormat('it-IT', {
      style: 'currency', // set style to currency
      currency: result.accounts[0].balance.currency, // use the currency key from your transaction data
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return result.accounts[0].balance.amount; // Return the formatted balance
  } catch (error) {
    console.error('Error fetching bank account balance:', error);
  }
}

export async function fetchBankTransferFeeSWIFT(payment_type, from, to, amount, currency, priority, beneficiary_bank_code) {
  const token = getCookieValue('firebaseToken');
  try {
    const formData = new FormData();
    formData.append('payment_type', payment_type);
    formData.append('from', from);
    formData.append('to', to);
    formData.append('amount', amount);
    formData.append('currency', currency);
    formData.append('priority', priority);
    formData.append('beneficiary_bank_code', beneficiary_bank_code);
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/baas/accounts/payment_calculate_fee`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: formData
    });
    const result = await response.json();
    return result.calculate_payments[0].fee_amount; // Return the formatted balance
  } catch (error) {
    console.error('Error fetching SWIFT fee:', error);
  }
}

export async function get_basic_user_list() {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/customers/list`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}
// Util_API_calls.js

export const get_financial_data = async (format, date_start, date_end) => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/financials/data`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        format: format,
        date_start: date_start,
        date_end: date_end
      })
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the API:', error);
    return { status: 'error', message: error.message };
  }
};


export async function markMessageAsRead(msg_id) {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/user/messages/mark_as_read`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ msg_id: msg_id })
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}

export async function fetchSendLoginOTP() {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/send_otp_ladmin`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        device_type: 'desktop'
      })
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}


export async function fetchVerifyLoginOTP(otpCode) {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/auth/login-mfa/verify-otp`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        otp_code: otpCode,
        otp_type: 'login'
      })
    });

    const result = await response.json();

    if (!response.ok) {
      return { status: 'error', message: result.message, details: result.details };
    }

    // Return success
    return { status: 'success', data: result };
  } catch (error) {
    console.error('Error calling the API:', error);
    return { status: 'error', errorDetails: { general: ['Network error occurred.'] } };
  }
}

export async function force_update_baas_token(otpCode) {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/emergency/force_update_baas_token`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    const result = await response.json();

    if (!response.ok) {
      return { status: 'error', message: result.message, details: result.details };
    }

    // Return success
    return { status: 'success', data: result };
  } catch (error) {
    console.error('Error calling the API:', error);
    return { status: 'error', errorDetails: { general: ['Network error occurred.'] } };
  }
}

export async function update_epay_catalog(otpCode) {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/emergency/update_epay_catalog`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    const result = await response.json();

    if (!response.ok) {
      return { status: 'error', message: result.message, details: result.details };
    }

    // Return success
    return { status: 'success', data: result };
  } catch (error) {
    console.error('Error calling the API:', error);
    return { status: 'error', errorDetails: { general: ['Network error occurred.'] } };
  }
}

export async function get_fee_values() {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/financials/prices`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}


// Util_API_calls.js


export const check_email_existence = async (email) => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/customers/check/email`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ email }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error checking email existence:', error);
    return { status: 'error', message: error.message };
  }
};

// Util_API_calls.js


{/*  falsa dashboard 

export const fetchPageDashboard = async () => {
  try {
    // Simulate an async operation, e.g., fetching data
    return await new Promise((resolve) => {
      setTimeout(() => resolve(homeProdData), 100); // Simulating async fetch with setTimeout
    });
  } catch (error) {
    console.error('Error reading the JSON file:', error);
    return { status: 'error', message: error.message };
  }
};*/}


export const fetchPageDashboard = async () => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/pages/dashboard`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the API:', error);
    return { status: 'error', message: error.message };
  }
};



export const fetchPageVolumes = async (format, date_start, date_end) => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/pages/volumes`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        format: format,
        date_start: date_start,
        date_end: date_end
      })
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the API:', error);
    return { status: 'error', message: error.message };
  }
};


export const fetchMasterAccountBalance = async () => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/master-account/balance`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    console.log('API Response:', result); // Log the full API response
    return { status: 'success', data: result };
  } catch (error) {
    console.error('Error calling the API:', error.message); // Improved error handling
    return { status: 'error', message: error.message };
  }
};


export const fetchMasterAccountDiagnostics = async (month, year) => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/master-account/diagnostics`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ month, year })
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    console.log('Diagnostics API Response:', result); // Log the full API response
    return { status: 'success', data: result };
  } catch (error) {
    console.error('Error calling the Diagnostics API:', error.message); // Improved error handling
    return { status: 'error', message: error.message };
  }
};


export const fetch_customer_details = async (customer_id) => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/customers/details`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ customer_id }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the API:', error);
    return { status: 'error', message: error.message };
  }
};


// Util_API_calls.js

export const fetch_customer_bank_accounts = async (customer_id) => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/customers/bankaccounts/list`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ customer_id }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the API:', error);
    return { status: 'error', message: error.message };
  }
};


// Util_API_calls.js

export const fetch_customer_transactions = async (customer_id) => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/customers/bankaccounts/transactions`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ customer_id }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the API:', error);
    return { status: 'error', message: error.message };
  }
};

export const fetch_customer_cards = async (customer_id) => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/customers/cards/list`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ customer_id }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the API:', error);
    return { status: 'error', message: error.message };
  }
};

export const fetch_card_transactions = async (customer_id, card_id) => {
  if (!customer_id || !card_id) {
    console.error('customer_id or card_id is missing');
    return { status: 'error', message: 'Customer ID and Card ID are required', card_transactions: [] };
  }

  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/customers/cards/transactions`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ customer_id, card_id }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    console.log('API response:', result);

    if (result.status === 'success' && Array.isArray(result.card_transactions)) {
      return result;
    } else {
      return { 
        status: 'error', 
        message: result.message || 'Non ci sono dettagli al momento',
        card_transactions: []
      };
    }
  } catch (error) {
    console.error('Error calling the API:', error);
    return { 
      status: 'error', 
      message: error.message,
      card_transactions: []
    };
  }
};

export const fetch_kyc_status = async (email) => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/customers/kyc/status-on-sumsub`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ email }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the API:', error);
    return { status: 'error', message: error.message };
  }
};

export const send_email = async (subject, message, emails) => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/customers/send_email`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ subject, message, emails, from: "dario.vanin@gmail.com, dario.vanin@icloud.com, dario@itenabler.com.au" }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the API:', error);
    return { status: 'error', message: error.message };
  }
};

// Util_API_calls.js
export const enableCustomer = async (customer_id) => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/customers/enable`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json', // Correctly set the Accept header
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ customer_id }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the enable API:', error);
    return { status: 'error', message: error.message };
  }
};




// Util_API_calls.js
export const disableCustomer = async (customer_id) => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/customers/disable`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json', // Correctly set the Accept header
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ customer_id }),
    });
    if (!response.ok) {
      const errorResponse = await response.json();
      console.error('Error response from server:', errorResponse);
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorResponse.message}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the disable API:', error);
    return { status: 'error', message: error.message };
  }
};
  
// Util_OTP_doubleauth.js
export const sendOTPDoubleAuth = async (delivery_method) => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/auth/universal-double-auth/send-verification`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({delivery_method }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the API:', error);
    return { status: 'error', message: error.message };
  }
};

export const verifyOTPDoubleAuth = async (otp_code) => {
  const token = getCookieValue('firebaseToken');
  try {
    console.log('Sending OTP to API:', otp_code); // Log the OTP being sent
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/auth/universal-double-auth/verify-otp`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        otp_code: otp_code
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    console.log('API response:', result); // Log the API response
    return result;
  } catch (error) {
    console.error('Error verifying OTP:', error);
    return { status: 'error', message: error.message };
  }
};

export const disableService = async (serviceData) => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/systems_health/disable-service`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(serviceData),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    console.log('Disable Service API Response:', result);
    if (result.status === 'success' && result.details.startsWith('Systems are scheduled for maintenance from')) {
      return result;
    } else {
      throw new Error(result.details || 'Unexpected error');
    }
  } catch (error) {
    console.error('Error calling the disable service API:', error);
    return { status: 'error', message: error.message };
  }
};


export const enableService = async (serviceCode) => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/systems_health/enable-service`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ service_code: serviceCode }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    console.log('Enable Service API Response:', result);
    if (result.status === 'success') {
      return result;
    } else {
      throw new Error(result.details || 'Unexpected error');
    }
  } catch (error) {
    console.error('Error calling the enable service API:', error);
    return { status: 'error', message: error.message };
  }
};


export const blockCustomerAccount = async (customer_id, account_id) => {
  if (!account_id) {
    console.error('account_id is missing');
    return { status: 'error', message: 'Account ID is required' };
  }

  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/customers/bankaccounts/block`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        customer_id: customer_id,
        account_id: account_id
      })
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    console.log('Block Customer Account API Response:', result);
    return result;
  } catch (error) {
    console.error('Error calling the block customer account API:', error);
    return { status: 'error', message: error.message };
  }
};

// Util_API_calls.js
export const fetch_aml_watchdog_events = async (customer_id) => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/customers/aml-watchdog-events`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ customer_id }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the AML Watchdog API:', error);
    return { status: 'error', message: error.message };
  }
};


export const update_user_email = async (customer_id, new_email) => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/customers/update/email`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ customer_id, new_email }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the update email API:', error);
    return { status: 'error', message: error.message };
  }
};


export const removeEpayLowFidoBlocker = async () => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/emergency/epay/remove-low-fido-blocker`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    console.log('Remove Epay Low Fido Blocker API Response:', result);
    if (result.status === 'success') {
      return result;
    } else {
      throw new Error(result.details || 'Unexpected error');
    }
  } catch (error) {
    console.error('Error calling the remove Epay low fido blocker API:', error);
    return { status: 'error', message: error.message };
  }
};


export const showCachedTokenUnlimit = async () => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/emergency/unlimit/show-cached-token`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    console.log('Show Cached Token Unlimit API Response:', result);
    if (result.status === 'success') {
      return result;
    } else {
      throw new Error(result.details || 'Unexpected error');
    }
  } catch (error) {
    console.error('Error calling the show cached token Unlimit API:', error);
    return { status: 'error', message: error.message };
  }
};


export const disconnectAllUsers = async () => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/emergency/redis/truncate-cache`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error disconnecting all users:', error);
    return { status: 'error', message: error.message };
  }
};

export const  forceUpdateBaasToken= async () => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/emergency/force_update_baas_token`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error forcing BAAS token update:', error);
    return { status: 'error', message: error.message };
  }
};

export const cleanupUnconfirmedEpayTransactions = async () => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/emergency/cleanup-unconfirmed-epay-transactions`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error cleaning up unconfirmed Epay transactions:', error);
    return { status: 'error', message: error.message };
  }
};

export const materialiseBankAccountBalances = async () => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/emergency/materialise-account-balances`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error materialising bank account balances:', error);
    return { status: 'error', message: error.message };
  }
};


export const removeEpayFidoBlocker = async () => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/emergency/epay/remove-low-fido-blocker`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error removing Epay Fido blocker:', error);
    return { status: 'error', message: error.message };
  }
};

export const materialiseAdminFinancials = async (dateStart, dateEnd) => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/emergency/materialise-admin-financials`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        date_start: dateStart,
        date_end: dateEnd
      })
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error materialising admin financials:', error);
    return { status: 'error', message: error.message };
  }
};


export const compareEpayTransactions = async (csvContent) => {
  const token = getCookieValue('firebaseToken');

  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/epay/record-transactions-from-csv`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'text/plain',
        'Authorization': `Bearer ${token}`
      },
      body: csvContent
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error comparing Epay transactions:', error);
    return { status: 'error', message: error.message };
  }
};


export const fetchSystemsHealth = async () => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/systems_health/services-status`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching systems health:', error);
    return { status: 'error', message: error.message };
  }
};


export const fetchTwilioStatus = async () => {
  try {
    const response = await fetch('https://status.twilio.com/api/v2/summary.json');
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    
    // Extract SMS related components (excluding MMS)
    const smsComponents = data.components.filter(component => 
      component.name.toLowerCase().includes('sms') && 
      !component.name.toLowerCase().includes('mms')
    );

    return {
      sms: smsComponents
    };
  } catch (error) {
    console.error('Error fetching Twilio status:', error);
    throw error;
  }
};

export const fetchSendGridStatus = async () => {
  try {
    const response = await fetch('https://status.sendgrid.com/api/v2/summary.json');
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    
    // Extract email related components
    const emailComponents = data.components.filter(component => 
      component.name === "Mail Sending" ||
      component.name === "Event Webhook" ||
      component.name === "Parse API" ||
      component.name === "Email Activity"
    );

    return emailComponents;
  } catch (error) {
    console.error('Error fetching SendGrid status:', error);
    throw error;
  }
};


export const fetch_customer_shipping_addresses = async (customer_id) => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/customers/shipping-addresses/list`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ customer_id }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error fetching customer shipping addresses:', error);
    return { status: 'error', message: error.message };
  }
};


export const update_user_phone = async (customer_id, new_phone) => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/customers/update/phone`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ customer_id, new_phone }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the update phone API:', error);
    return { status: 'error', message: error.message };
  }
};

export const refundCustomer = async (refundData) => {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/emergency/refund-customer`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        customer_id: refundData.customer_id,
        amount: parseFloat(refundData.amount).toFixed(2), // Ensure two decimal places
        currency: refundData.currency,
        description: refundData.description
      }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    console.log('Refund Customer API Response:', result);
    if (result.status === 'success') {
      return result;
    } else {
      throw new Error(result.message || 'Unexpected error');
    }
  } catch (error) {
    console.error('Error calling the refund customer API:', error);
    return { status: 'error', message: error.message };
  }
};

// Fuction to get the companies to do KYB 


export const get_business_customer_list = async () => {
const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/business/list-no-risk-score`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the API:', error);
    return { status: 'error', message: error.message };
  }
};


export const updateBusinessRiskScore = async (
  business_id,
  general_information_how_client_get_to_know_paynovate,
  general_information_how_was_the_client_met,
  general_information_what_is_the_client_sector_category,
  general_information_is_there_a_clear_view_of_the_client_business_activity,
  general_information_does_business_activity_make_sense_for_the_account,
  general_information_where_is_the_client_incorporated,
  general_information_incorporation_country_name,
  general_information_sanctions,
  source_of_financing_adverse_media,
  source_of_financing_has_client_provided_audited_statements,
  source_of_financing_does_sow_sof_make_sense,
  source_of_financing_is_sow_sof_identifiable,
  source_of_financing_funds_from_reputable_institution,
  source_of_financing_funds_from_personal_accounts,
  source_of_financing_funds_from_third_parties,
  verification_has_client_provided_proof_of_identification,
  verification_have_you_confirmed_authenticity_of_certifier,
  verification_was_client_hesitant_in_providing_documents,
  verification_sanctions_screening_provided_positive_hit,
  ubo_pep,
  ubo_sanctions,
  ubo_adverse_media,
  ubo_residing_in_sanctioned_country,
  ubo_residing_in_high_risk_country,
  directors_pep,
  directors_sanctions,
  directors_adverse_media,
  directors_residing_in_sanctioned_country,
  directors_residing_in_high_risk_country,
  transactions_did_client_provide_expected_monthly_inwards_volume,
  transactions_expected_annual_incoming_volume,
  transactions_customer_provided_expected_monthly_outwards_volume,
  transactions_expected_annual_outgoing_volume,
  transactions_customer_provided_5_counterparties_inwards,
  transactions_customer_provided_5_counterparties_outwards,
  transactions_is_client_involved_in_cash_dominant_turnover,
  transactions_is_transaction_risk_of_money_laundering
) => {
  const token = getCookieValue('firebaseToken');
  try {
    const params = {
      business_id: business_id,  // Explicitly mapping business_id
      general_information_how_client_get_to_know_paynovate,
      general_information_how_was_the_client_met,
      general_information_what_is_the_client_sector_category,
      general_information_is_there_a_clear_view_of_the_client_business_activity,
      general_information_does_business_activity_make_sense_for_the_account,
      general_information_where_is_the_client_incorporated,
      general_information_incorporation_country_name,
      general_information_sanctions,
      source_of_financing_adverse_media,
      source_of_financing_has_client_provided_audited_statements,
      source_of_financing_does_sow_sof_make_sense,
      source_of_financing_is_sow_sof_identifiable,
      source_of_financing_funds_from_reputable_institution,
      source_of_financing_funds_from_personal_accounts,
      source_of_financing_funds_from_third_parties,
      verification_has_client_provided_proof_of_identification,
      verification_have_you_confirmed_authenticity_of_certifier,
      verification_was_client_hesitant_in_providing_documents,
      verification_sanctions_screening_provided_positive_hit,
      ubo_pep,
      ubo_sanctions,
      ubo_adverse_media,
      ubo_residing_in_sanctioned_country,
      ubo_residing_in_high_risk_country,
      directors_pep,
      directors_sanctions,
      directors_adverse_media,
      directors_residing_in_sanctioned_country,
      directors_residing_in_high_risk_country,
      transactions_did_client_provide_expected_monthly_inwards_volume,
      transactions_expected_annual_incoming_volume,
      transactions_customer_provided_expected_monthly_outwards_volume,
      transactions_expected_annual_outgoing_volume,
      transactions_customer_provided_5_counterparties_inwards,
      transactions_customer_provided_5_counterparties_outwards,
      transactions_is_client_involved_in_cash_dominant_turnover,
      transactions_is_transaction_risk_of_money_laundering
    };

    const response = await fetch(`${config.API_DOMAIN_ADMIN}/api/v2/admin/business/update-risk-score`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(params),
    })
    if (!response.ok) {
      const errorText = await response.text();
      console.error('API Error Response:', errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    console.log('API Success Response:', result);  // Added console log for success
    return result;
  } catch (error) {
    console.error('Error calling the update risk score API:', error);
    return { status: 'error', message: error.message };
  }
};